<template>
  <div class="anchor-container">
    <div class="banner">
      <div class="banner-inner">
        <img src="https://mktv-in-cdn.mockuai.com/17007268314906134.png" class="desc-img" alt="">
      </div>
    </div>
    <div class="content">
      <img src="https://mktv-in-cdn.mockuai.com/17007253254088154.png" alt="">
      <img src="https://mktv-in-cdn.mockuai.com/17007253254097289.png" alt="">
      <img src="https://mktv-in-cdn.mockuai.com/17007253254111489.png" alt="">
      <img src="https://mktv-in-cdn.mockuai.com/17007253254138333.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: '魔影推推',
    meta: [
      {
        name: 'Keywords',
        content:
          '魔影推推，AI智能工具',
      },
      {
        name: 'Description',
        content:
          '魔影推推，AI智能工具',
      },
    ],
  },
}
</script>

<style lang="less" scoped>
.anchor-container {
  padding-top: 90px;
}
.banner {
  background: url(https://mktv-in-cdn.mockuai.com/17007253254142839.png) no-repeat center;
  height: 680px;
    background-size: 100% 100%;
  &-inner {
    width: 1440px;
    height: 680px;
    background-size: 100% 100%;
    position: relative;
    margin: 0 auto;
  }
  .desc-img {
    position: absolute;
    top: 161px;
    left: 0;
    width: 672px;
    height: 286px;
  }
}
.content {
  width: 1440px;
  margin: 0 auto;
  padding: 40px 0;
  img {
    display: block;
    height: 620px;
    margin-bottom: 20px;
  }
}
</style>
